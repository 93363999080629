<script setup lang="ts">
import telenorid from "~/telenorid/telenorid";

definePageMeta({
	layout: false,
});

onBeforeMount(async () => {
	const { query } = useRoute();
	if (query?.callback) telenorid.setCallbackRedirectUrl(query.callback);
	if (query.aal3) {
		await telenorid.signinRedirect({
			acr_values: "urn:telenor.identity.aal.3",
		});
	} else {
		await telenorid.signinRedirect();
	}
});
</script>

<template>
	<NuxtLayout />
</template>
